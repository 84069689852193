import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { GatsbyImage, StaticImage, getImage } from 'gatsby-plugin-image';

// Atoms
import Button from '../atoms/button';
import ImageWrapper from '../atoms/image-wrapper';

// Components
import { generateUrlFromContentModel } from './helpers';

// Styled components
export const HeroWrapper = styled.div.attrs({
  className:
    'bg-gradient-180 from-blue-dark to-blue-bold text-white pt-header-height-mobile lg:pt-header-height-desktop',
})``;

const Hero = ({ title, description, ctas, image, tagline }) => {
  const img = getImage(image);

  return (
    <HeroWrapper>
      <div className="xl:container mobile:px-4 tablet:px-8 lg:px-16 pt-8 lg:pt-24 pb-10 lg:pb-16">
        <div className="lg:flex lg:items-start lg:justify-between lg:flex-row-reverse">
          <div className="mb-6 lg:mb-0 lg:flex-initial lg:w-5/12">
            <ImageWrapper>
              {img ? (
                <GatsbyImage
                  image={img}
                  // title={image.title}
                  alt={image.description}
                />
              ) : (
                <StaticImage
                  src="../images/placeholder_auto.jpg"
                  alt="Toyota SUV driving through a forest"
                />
              )}
            </ImageWrapper>
          </div>
          <div className="lg:flex-initial lg:w-6/12 space-y-8 lg:space-y-10">
            <div className="space-y-3">
              <h1 className="u-h2">{title}</h1>
              {description && (
                <p className="u-body-lg lg:whitespace-pre-line">
                  {description}
                </p>
              )}
            </div>
            {ctas && ctas.length > 0 && (
              <div className="flex flex-wrap -mb-3">
                {ctas.map((cta, key) => (
                  <Button
                    key={`hero-cta-button-${key}`}
                    as={
                      (cta?.internalUrl && cta?.internalUrl.length) ||
                      cta?.externalUrl
                        ? 'a'
                        : 'button'
                    }
                    variant={cta?.variant.toLowerCase()}
                    href={
                      cta?.internalUrl && cta?.internalUrl.length > 0
                        ? generateUrlFromContentModel(cta?.internalUrl[0])
                        : cta?.externalUrl
                    }
                    label={cta?.label || cta.title}
                    aria-label={cta?.ariaLabel}
                    newTab={cta?.opensInNewTab}
                    className="mb-3 mr-3 last:mr-0"
                  />
                ))}
              </div>
            )}
          </div>
        </div>
        {tagline && <p className="u-h4 text-center mt-9 lg:mt-20">{tagline}</p>}
      </div>
    </HeroWrapper>
  );
};

Hero.defaultProps = {
  title: '',
  description: '',
  ctas: [],
  image: {},
  tagline: '',
};

Hero.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  ctas: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      label: PropTypes.string,
      ariaLabel: PropTypes.string,
      variant: PropTypes.oneOf(['Primary', 'Secondary', 'Tertiary']),
      opensInNewTab: PropTypes.bool,
      externalUrl: PropTypes.string,
      internalUrl: PropTypes.arrayOf(PropTypes.object),
    })
  ),
  image: PropTypes.object,
  tagline: PropTypes.string,
};

export default Hero;

import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

// Logos
import EmblemBasicLogo from '../logos/logo-emblem-basic.svg';
import ThickerLogo from '../logos/logo-thicker.svg';

const ImageWrapper = ({ children, variant, ...props }) => {
  return (
    <div className="h-full relative">
      {variant === 'secondary' ? (
        <img
          src={EmblemBasicLogo}
          alt=""
          className={cn(
            'mobile+tablet:hidden absolute w-20 h-20 pointer-events-none touch-none opacity-50',
            props.className ||
              'top-0 left-0 transform -translate-x-1/2 -translate-y-1/2'
          )}
        />
      ) : (
        <img
          src={ThickerLogo}
          alt=""
          className={cn(
            'mobile+tablet:hidden absolute w-20 h-20 xl:w-32 xl:h-32 pointer-events-none touch-none opacity-50 z-10',
            props.className ||
              'top-0 right-0 transform translate-x-1/2 -translate-y-1/2'
          )}
        />
      )}

      {children}
    </div>
  );
};

ImageWrapper.defaultProps = {
  variant: 'primary',
};

ImageWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  variant: PropTypes.oneOf(['', 'primary', 'secondary']), // primary is hero
};

export default ImageWrapper;
